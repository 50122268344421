import styles from "./Loading.module.css";
import Spinner from "../../assets/SpinnerWhite.svg";


const Loading = () =>{
return(
    <div>
          <img src={Spinner} className={styles.loadingImg} alt="Loading Spinner"/>
    </div>
)
};

export default Loading;

