import styles from "./ReturnedFoods.module.css";
import editIcon from "../../assets/edit.png";
import deleteIcon from "../../assets/delete.png";
import rarelyIcon from "../../assets/rarelyCode.png";
import okIcon from "../../assets/okCode.png";
import neverIcon from "../../assets/neverCode.png";
import infrequentIcon from "../../assets/infrequentCode.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MessageBox from "../messageBox/MessageBox";
import { httpsCallable } from "firebase/functions";
import { cloudFuncs } from "../../firebase/config";
import Loading from "../loading/Loading";


const ReturnedFoods = ({foodList, onSuccess, searchString }) =>{

    const handleDelete = async (id)=>{     
        console.log(id);
        let response;
        let isError;
        const fn = httpsCallable(cloudFuncs, "deleteFoodItem");
        try{
            response = await fn({id});
            console.log(response.data);
            if (response.data.error){
                isError = true;
            }
            else{
                isError = false;
            }
        }
        catch(err){
            console.log(err);
            isError = true;
        };
        console.log('line 37,', isError);
        return isError;
    };
    


    return(
        <div className={styles.foodListContainer}> 
            {foodList.map((food)=><FoodItem searchString={searchString} onSuccess={onSuccess} onDelete={async()=>{return await handleDelete(food.objectID)}} food={food} key={food.objectID}/>)}
        </div>
    )
}
export default ReturnedFoods;


const FoodItem = ({food, onDelete, onSuccess, searchString}) =>{
    
    const [showMessageBox, setShowMessageBox] = useState(false);
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    
    const handleDelete = async() =>{
        let isError;
        setShowMessageBox(false);
        setIsLoading(true);
        isError = await onDelete();
        console.log('error:', isError);
        setIsLoading(false);
        if (isError){
            console.log('error')
            setHasError(true);
        }
        else{
            console.log('success');
            onSuccess();
        }
    };
   
    const handleEdit=()=>{
        console.log('line 77', searchString);
        navigate("/food/edit", {state:{food:food, searchString:searchString}})
    };

    const confirmDelete=()=>{
        setShowMessageBox(true);
    };

    const getCode = (code) =>{
        if (code === 'NEVER'){
            return (<img src={neverIcon} alt='Never Icon' className={styles.iconNever}/>);
        };
        if (code === 'INFREQUENT'){
            return (<img src={infrequentIcon} alt='Infrequent Icon' className={styles.iconInfrequent}/>);
        };
        if (code === 'RARELY'){
            return (<img src={rarelyIcon} alt='Rarely Icon' className={styles.iconRarely}/>);
        };
        if (code === 'OK'){
            return (<img src={okIcon} alt='OK Icon' className={styles.iconOk}/>);
        };
    };

    return(
        <>
        <div className={styles.foodItem}>
            <div className={styles.row}>
            {getCode(food.edibilityCode)}
            <p className={styles.foodName}>{food.foodName}</p>
            </div>
            <div className={styles.iconContainer}>
                <img onClick={handleEdit} className={styles.editIcon} src={editIcon} alt="Edit Icon"/>
                <img onClick={confirmDelete} className={styles.deleteIcon} src={deleteIcon} alt="Delete Icon"/>
            </div>
        </div>
        {hasError && <p className={styles.errorMsg}>Error deleting food item. Please try again.</p>}
        {showMessageBox && <MessageBox info={'Do you really want to delete this food item?'}
            mainBtnHandler={handleDelete} cancelBtnHandler={()=>{setShowMessageBox(false)}}/>}
        {isLoading && <Loading />}
        </>
      

    );
};