import { createContext, useState } from "react";
import { auth } from "../firebase/config";

export const AuthContext = createContext({
    email:'',
    uid:'',
    loggedIn:false,
    login:(email, uid)=>{},
    logout:()=>{},
});

const checkLoggedInStatus = () =>{
    const user = auth.currentUser;
    console.log('line 14, user', user);
    return user;
}


const AuthContextProvider = ({children}) =>{
    
    const user = checkLoggedInStatus();
    const [isLoggedIn, setIsLoggedIn] = useState(user ? true: false);
    const [authEmail, setAuthEmail] = useState(user ? user.email : null);
    const [authUserId, setAuthUserId] = useState(user ? user.uid: null);

    const login = (email, uid) =>{
        console.log('line 25, logging in');
        setIsLoggedIn(true);
        setAuthUserId(uid);
        setAuthEmail(email);
    };

    const logout = () => {
        console.log('line 34, logging out');
        setAuthEmail('');
        setAuthUserId(null);
        setIsLoggedIn(false);
      };

      const value = {
        uid:authUserId,
        email:authEmail,
        loggedIn:isLoggedIn,
        login:login,
        logout:logout,
      };

      return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthContextProvider;