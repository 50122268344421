import styles from "./Admin.module.css";
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/navBar/NavBar";



const Admin = () =>{

    const navigation = useNavigate();

    const handleFoodList = () =>{
        navigation('/food');
    };

    return(
        <div className={styles.container}>
        <div>
            <NavBar showAdmin={false}/>
        </div>
        <h1 className={styles.title}>Admin Menu</h1>
        <div className={styles.menuLink} onClick={handleFoodList}><p className={styles.menuText}>Maintain Cat and Dog Food List</p></div>
        {/* <div className={styles.menuLink2}><p className={styles.menuText2}>Something else goes here...</p></div> */}
        </div>
    )
};
export default Admin;