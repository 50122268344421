import styles from "./MessageBox.module.css";

const MessageBox = ({
  info,
  mainBtnHandler,
  cancelBtnHandler,
}) => {

  return (
    <div className={styles.container}>
        <div><p className={styles.info}>{info}</p></div>
        <div className={styles.btnContainer}>
        <div className={styles.btn} onClick={cancelBtnHandler}>
            <p className={styles.btnText}>CANCEL</p>
        </div>

        <div className={styles.btn} onClick={mainBtnHandler}>
            <p className={styles.btnText}>OKAY</p>
        </div>
        </div>
    </div>
  );
};
export default MessageBox;
