import styles from "./Food.module.css";
import radioBtnChecked from "../../assets/radio_button_checked.png";
import radioBtnUnchecked from "../../assets/radio_button_unchecked.png";
import searchBtn from "../../assets/search.png";
import { useState} from "react";
import ReturnedFoods from "../../components/returnedFoods/ReturnedFoods";
import NavBar from "../../components/navBar/NavBar";
import addIcon from "../../assets/add_food.png";
import algoliasearch from "algoliasearch";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { useLocation } from "react-router-dom";


const Food = ()=>{

    const location = useLocation();
    const criteria = location.state && location.state.searchString ? location.state.searchString : '';
    const animal = location.state && location.state.animal ? location.state.animal : 'CAT';
    console.log('line 21', location.state);
    console.log('line 21',criteria);

    const [chooseCat, setChooseCat] = useState(animal === 'CAT' ? true : false);
    const [chooseDog, setChooseDog] = useState(animal === 'DOG' ? true: false);
    const [foodList, setFoodList] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState(criteria);
    const [showSearchList, setShowSearchList] = useState(false);
    const [enableSearch, setEnableSearch] = useState(criteria ? true : false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const onSuccessDelete = () =>{
        console.log('line 27, success delete');
        setShowSearchList(false);
    };

    const handleRadioBtn = (animalType) =>{
        setFoodList([]);
        if (animalType === 'CAT'){
            setChooseCat(true);
            setChooseDog(false);
        }
        else{
            setChooseCat(false);
            setChooseDog(true);
        };
    };

    const addNewFood = () =>{
        const animalType = chooseCat ? "CAT" :"DOG";
        console.log('line 39,',animalType);
        navigate("/food/add", {state:{foodName:searchCriteria,searchString:searchCriteria, animalType:animalType}})

    };

    const handleSearchCriteria = (e) =>{
        const searchString = e.target.value;
        if (searchString && searchString.length >=1 && searchString!== ' ')
        {
            setEnableSearch(true);
        }
        else{
            setEnableSearch(false);
        };
        setSearchCriteria(e.target.value);
    };

    const handleSearchFoods = async() =>{
        setIsLoading(true);
        const searchFilter = chooseCat ? 'animalType:CAT' : 'animalType:DOG';
        const appID = "ZKPIFSG8PV";
        const apiKey = "9cf7458fbfffa8c5ad2de9f55d2e910d";
        
        // Connect and authenticate with your Algolia app
        const client = algoliasearch(appID, apiKey);
        const index=client.initIndex('FOOD_EDIBILITY');
        
        // Search the index for "test" and print the results
        const { hits } = await index.search(searchCriteria,{ filters: searchFilter});
        console.log('hits', hits);
        const tempFoodList = [];
        let i = 0;
        for (const item of hits){
            tempFoodList[i] = {modifiedAt:item.lastmodified, animalType:item.animalType,edibilityCode:item.edibilityCode, foodName:item.foodName, source:item.source, objectID:item.objectID}
            i++;
        };
        console.log('line 64',tempFoodList);
        setFoodList(tempFoodList);
        setIsLoading(false);
        setShowSearchList(true);
    }
    
    return(
    <>
    <div className={styles.container}>
        
        <NavBar showAdmin={true} />
        <h1 className={styles.title}>Maintain Cat and Dog Food Information</h1>
        <div className={styles.pickAnimalContainer}>
            <div className={styles.animal}>
                <img onClick={()=>{handleRadioBtn('CAT')}} src={chooseCat ? radioBtnChecked : radioBtnUnchecked} className={styles.btn} alt="Radio Button"/>
                <p className={styles.animalText}>Cat</p>
            </div>
            <div className={styles.animal}>
                <img onClick={()=>{handleRadioBtn('DOG')}} src={chooseDog ? radioBtnChecked : radioBtnUnchecked} className={styles.btn} alt="Radio Button"/>
                <p className={styles.animalText}>Dog</p>
            </div>
        </div>
        <div className={styles.searchContainer}>
            <label className={styles.searchLabel}>{`${chooseCat ? 'Cat': 'Dog'} Food Search:`}</label>
            <input
                className={styles.searchText}
                type="text" 
                value={searchCriteria}
                onChange={(e)=>{handleSearchCriteria(e)}}
            />
            <img onClick={()=>{handleSearchFoods()}} src={searchBtn} className={enableSearch ? styles.searchBtn : styles.disabledSearchBtn} alt="Search Btn"/>
            {showSearchList && <img  onClick={addNewFood} className={styles.addBtn} src={addIcon} alt="Add Food Icon"/>}
        </div>
        {isLoading && <Loading />}
        <div>
            {showSearchList && <ReturnedFoods searchString={searchCriteria} onSuccess={onSuccessDelete} foodList={foodList} animalType={chooseCat ? 'CAT' : 'DOG'}/>}
        </div>
       
    </div>
    </>
    )
};
export default Food;
