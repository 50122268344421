import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import rarelyIcon from "../../assets/rarelyCode.png";
import okIcon from "../../assets/okCode.png";
import neverIcon from "../../assets/neverCode.png";
import infrequentIcon from "../../assets/infrequentCode.png";
import styles from "./EditFood.module.css";
import LogoHeader from "../../components/logoHeader/LogoHeader";
import CancelSaveBtns from "../../components/cancelSaveBtns/CancelSaveBtns";
import { cloudFuncs } from "../../firebase/config";
import { httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import logo from "../../assets/crosstailsroundlogo.png";

const EditFood = () =>{
    const location = useLocation();
    const navigate = useNavigate();
    const [showLoading, setShowLoading] = useState(false);
    const [foodName, setFoodName] = useState(location.state.food.foodName);
    const [source, setSource] = useState(location.state.food.source);
    const [code, setCode] = useState(location.state.food.ediblityCode);
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [disableBtns, setDisableBtns] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    useEffect(()=>{
        setCode(location.state.food.edibilityCode);
    },[location])

   
    const onSave = async()=>{
        setShowError(false);
        setShowLoading(true);
        setDisableBtns(true);
        setDisableSave(true);
        console.log('line 27 save new data', foodName, source, code);
        const animalType = location.state.food.animalType;
        const objectID = location.state.food.objectID;
        let response;
        const fn = httpsCallable(cloudFuncs, "updateFoodItem");
        try{
            response = await fn({objectID, animalType, foodName, source, edibilityCode:code});
            if (response.data.error){
                console.log(response.data.msg);
                setShowLoading(false);
                setShowError(true);
                setDisableBtns(false);
                setDisableSave(false);
                setErrorMsg('Error saving food changes. Please try again.');
            }
            else{
                setShowLoading(false);
                setDisableBtns(false);
                setDisableSave(false);
                console.log('line 38 success');
                const searchString = location.state.searchString;
                navigate('/food', {state:{searchString:searchString, animal:location.state.food.animalType}})
            };
        }
        catch(err){
            setShowLoading(false);
            setDisableBtns(false);
            setDisableSave(false);
            console.log(err);
            setShowError(true);
            setErrorMsg('Error saving food changes. Please try again.');
        };
    };
    
    const onCancel = () =>{
        const searchString = location.state.searchString;
        navigate('/food', {state:{searchString:searchString, animal:location.state.food.animalType}})
    };

    const handleIcon = (iconCode) =>{
        
        if (disableSave){
            setDisableSave(false);
        };
        
        console.log('in Handle Icon',iconCode);
        setCode(iconCode);
    };

    const getCodeInfo = () =>{
        if (code === 'OK'){
            return (
                <div className={styles.descriptionContainer}>
                    <p className={styles.codeDescription}>{`Good! Not harmful.`}</p>
                    <p className={styles.codeDescription}>{`You may feed this to your ${location.state.food.animalType.toLowerCase()} in small portions.`}</p>
                </div>
            )
        };
        if (code === 'INFREQUENT'){
            return(
                <div className={styles.descriptionContainer}>
                    <p className={styles.codeDescription}>{`Okay. Serve in moderation, 1-2 small portions per week.`}</p>
                    <p className={styles.codeDescription}>{`You may feed this to your ${location.state.food.animalType.toLowerCase()}, but in very small quantities.`}</p>
                </div>
            )
        };
        if (code === 'RARELY'){
            return(
                <div className={styles.descriptionContainer}>
                    <p className={styles.codeDescription}>{`Rare occasion, but not advised.`}</p>
                    <p className={styles.codeDescription}>{`Do not serve more than one small portion of this to your ${location.state.food.animalType.toLowerCase()} per month.`}</p>
                </div>
            )
        };
        if (code === 'NEVER'){
            return(
                <div className={styles.descriptionContainer}>
                    <p className={styles.codeDescription}>{`Bad! Harmful. Toxic for your ${location.state.food.animalType.toLowerCase()}, or non-toxic but poses several health risks.`}</p>
                    <p className={styles.codeDescription}>{`Cross Tails recommends that you do not feed this to your ${location.state.food.animalType.toLowerCase()}.`}</p>
                </div>
            )
        };
    }

    const nameChange = (e) =>{

        if (disableSave && e.target.value.length !== 0) {
            setDisableSave(false);
        };
        if (e.target.value.length === 0){
            setDisableSave(true);
        };

        console.log(e.target.value);
        setFoodName(e.target.value);
    };

    const sourceChange = (e) =>{
        
        if (disableSave){
            setDisableSave(false);
        };
        
        console.log(e.target.value);
        setSource(e.target.value);
    };

    return(
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.rowItem}>
                    <img className={styles.logo} src={logo} alt="Crosstails Logo"/>
                </div>
                <div className={styles.rowItem}> 
                    <h1 className={styles.title}>{`Edit ${location.state.food.animalType[0]+location.state.food.animalType.slice(1).toLowerCase()} Food`}</h1>
                </div>
            </div>
         
            <div className={styles.editContainer}>
                <div className={styles.foodContainer}>
                    <p className={styles.label}>Food Name: </p>
                    <textarea
                        rows={2}
                        value={foodName}
                        className={styles.inputText}
                        onChange={nameChange}
                    />
                </div>
                <div>
                    <div className={styles.iconContainer}>
                        <img onClick={()=>handleIcon('OK')} src={okIcon} alt='OK Icon' className={code ==='OK' ? styles.iconSelected : styles.icon}/>
                        <img onClick={()=>{handleIcon('INFREQUENT')}} src={infrequentIcon} alt='Infrequent Icon' className={code ==='INFREQUENT' ? styles.iconSelected : styles.icon}/>
                        <img onClick={()=>{handleIcon('RARELY')}} src={rarelyIcon} alt='Rarely Icon' className={code ==='RARELY' ? styles.iconSelected : styles.icon}/>
                        <img onClick={()=>{handleIcon('NEVER')}} src={neverIcon} alt='Never Icon' className={code ==='NEVER' ? styles.iconSelected : styles.icon}/>
                    </div>
                    <div>
                    {getCodeInfo()}
                    </div>
                </div>
                <div className={styles.sourceContainer}>
                    <p className={styles.label}>Source:</p>
                    <textarea
                        rows={2}
                        value={source}
                        className={styles.inputText}
                        onChange={sourceChange}
                    />
                </div>
            </div>
            {showLoading && <Loading/>}
            {showError && <p className={styles.errorMsg}>{errorMsg}</p>}
            <CancelSaveBtns disableBtns={disableBtns} disableSave={disableSave} onSave={onSave} onCancel={onCancel}/>
        </div>
        
    )
};
export default EditFood;