import styles from "./Login.module.css";
import logo from "../../assets/crosstailsroundlogo.png";
import hidepassword from "../../assets/visibilityoff.png";
import showpassword from "../../assets/visible.png";
import { useState } from "react";
import { useLogin } from "../../hooks/useLogin";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const Login = () =>{

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showLoginBtn, setShowLoginBtn] = useState(false);
    const [onError, setOnError] = useState(false);
    const authCtx = useContext(AuthContext);
    const {login} = useLogin();

    const checkEnableLoginBtn = (from, currentValue) =>{
        let show = false;
        if (from === 'email'){
            const currentPassword = password;
            if (currentValue !==null && currentValue.length >= 3 && currentValue.includes('@') && currentPassword !== null && currentPassword.length >= 8){
                show = true;
            } 
        }
        else {
            const currentEmail = email;
            if (currentValue !== null && currentValue.length >=8 && currentEmail !== null && currentEmail.length >= 3 && currentEmail.includes('@')){
                show = true;
            }
        }
        return(show);
    };

    const showPasswordHandler = () =>{
        setShowPassword((prev)=>!prev);
    };
    
    const handleEmail = (e) =>{
        if (onError){
            setOnError(false);
        };
        const currentEmail = e.target.value;
        setEmail(e.target.value);
        const enableLoginBtn = checkEnableLoginBtn('email', currentEmail);
        setShowLoginBtn(enableLoginBtn);
    };

    const handlePassword = (e) =>{
        if (onError){
            setOnError(false);
        };
        const currentPassword = e.target.value
        setPassword(e.target.value);
        const enableLoginBtn = checkEnableLoginBtn('password', currentPassword);
        setShowLoginBtn(enableLoginBtn);
    };
   
    const handleLogin = async() =>{
        // login to fb auth
        console.log(email, password);
        const res = await login(email, password);
       
        if (res.error){

            setOnError(true);
        }
        else{
            // update auth context to show logged in
            console.log(res.email, res.uid);
            
            authCtx.login(res.email,res.uid);
        };
    };

    return(
        <div className={styles.container}>
            <div className={styles.header}>
            <div className={styles.logo}>
                <img src={logo} alt="Crosstails Logo"/>
            </div>
            <div className={styles.title}><h1 className={styles.titleText}>Admin Login</h1></div>
            </div>
            <div className={styles.inputContainer}>
                <div className={styles.inputGrid}>
            
                <label className={styles.oneEmail}>Email:</label>
                <input
                    className={styles.twoEmail}
                    type="email" 
                    value={email}
                    onChange={(e)=>{handleEmail(e)}}
                />
                
                <label className={styles.onePassword}>Password:</label>
                <input
                    className={styles.twoPassword}
                    type={showPassword ? "text" : "password" }
                    value={password}
                    onChange={(e) => {handlePassword(e)}}
                />
        
                {!showPassword && <img onClick={showPasswordHandler} src={showpassword} className={styles.threePassword} alt="show password"/>}
                {showPassword && <img onClick={showPasswordHandler} src={hidepassword} className={styles.threePassword} alt="hide password"/>}
                </div>
            </div>
            {onError && <div><p className={styles.errorMsg}>Error logging in. Please try again with proper email and password.</p></div>}
            <div className={styles.buttonContainer}>
            <div onClick={handleLogin} className={showLoginBtn ? styles.buttonStyle : styles.buttonStyleDisabled}><p className={styles.buttonText}>Login</p></div>
            </div>
        </div>
    );
};
export default Login;
