import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDHCNEDyP-A7L4odnzFbC7iln8pI23fduI",
    authDomain: "cross-tails-c65d9.firebaseapp.com",
    projectId: "cross-tails-c65d9",
    storageBucket: "cross-tails-c65d9.appspot.com",
    messagingSenderId: "382757154818",
    appId: "1:382757154818:web:1b8b4b5d89372a5afd371b"
  };

// init firebase
const app = initializeApp(firebaseConfig);

// init firestore developlment database
const fireDB = getFirestore(app);

// init firestore auth
const auth = getAuth(app);

// init firebase functions
const cloudFuncs = getFunctions(app);

await setPersistence(auth, browserLocalPersistence);

export {fireDB, auth, cloudFuncs};