
import styles from "./CancelSaveBtns.module.css";

const CancelSaveBtns = ({disableBtns, disableSave, onSave, onCancel}) =>{


    return(
        <div className={styles.btnContainer}>
            {disableBtns &&<div  className={styles.disabled}><p >Cancel</p></div>}
            {!disableBtns &&<div  onClick={onCancel} className={styles.cancelBtn}><p >Cancel</p></div>}
            {disableSave &&<div  className={styles.disabled}><p >Save</p></div>}
            {!disableSave &&<div onClick={onSave} className={styles.saveBtn}><p >Save</p></div>}
        </div>
    );
};
export default CancelSaveBtns;