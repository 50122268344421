import logo from "../../assets/crosstailsroundlogo.png";
import styles from "./NavBar.module.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { useLogout } from "../../hooks/useLogout";


const NavBar = ({showAdmin}) =>{
    const authCtx = useContext(AuthContext);
    const {logoutUser} = useLogout();

    const handleLogout = async() =>{
        await logoutUser();
        authCtx.logout();
    };

    return(
        <div className={styles.navbar}>
              <div>
                <img className={styles.logo} src={logo} alt="Crosstails Logo"/>
            </div>
            {showAdmin && 
            <div className={styles.label}>
                <Link className={styles.labelLink} to={"/admin"}><p>Admin Menu</p></Link>
            </div>
            }
            <div onClick={handleLogout} className={styles.label}>
                <p>Logout</p>
            </div>
        </div>
    );
};
export default NavBar;