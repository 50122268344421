
import Login from "./screens/login/Login";
import { useContext } from "react";
import { Route, Routes, BrowserRouter,Navigate} from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import Admin from "./screens/admin/Admin";
import PageNotFound from "./screens/notFound/PageNotFound";
import Food from "./screens/food/Food";
import EditFood from "./screens/editFood/EditFood";
import AddFood from "./screens/addFood/AddFood";


function App() {
  const authCtx = useContext(AuthContext);
  const loggedIn = authCtx.loggedIn;
  console.log('line 13', authCtx.loggedIn);

  return (
  <>
    <BrowserRouter>
      <Routes>
        <Route
          exact path="/"
          element={loggedIn ? <Navigate to="/admin" /> : <Login/>}
        />
        <Route 
          path="/admin"
          element={!loggedIn ? <Navigate to="/"/> : <Admin/>}
        />
        <Route
          path="/food"
          element={!loggedIn ? <Navigate to="/"/> :<Food/>}
        />
         <Route
          path="/food/add"
          element={!loggedIn ? <Navigate to="/"/> : <AddFood/>}
        />
        <Route
          path="/food/edit"
          element={!loggedIn ? <Navigate to="/"/> : <EditFood/>}
        />
        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
    </BrowserRouter>
  </>
  );
};

export default App;
