
import {auth} from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";


export const useLogin = () =>{
    const login = async (email, password)=>{
        let errorMsg = null;
        let res;
        try {
            res = await signInWithEmailAndPassword(auth, email, password);
        }
        catch (err){
            errorMsg = err;
            return {error:true, msg:errorMsg};
        };
        return {error:false, email:res.user.email, uid:res.user.uid};
    };  
    return {login};
};